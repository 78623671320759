@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  /* * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  } */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  h1, h2, h3, h4, h5, h6 {
    @apply font-bold;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  body:has(dialog[open]) {
    @apply overflow-hidden;
  }

  dialog {
    @apply max-h-full;
  }

  dialog:not([open]) {
    @apply hidden;
  }
}

.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  display: none;
}

@supports (-webkit-touch-callout: none) {
  .gap-2.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  .gap-3.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 0.75rem;
  }

  .gap-4.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 1rem;
  }

  .gap-5.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 1.25rem;
  }

  .gap-6.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 1.5rem;
  }

  .gap-7.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 1.75rem;
  }

  .gap-8.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 2rem;
  }

  .gap-9.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 2.25rem;
  }

  .gap-10.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 2.5rem;
  }

  .gap-11.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 2.75rem;
  }

  .gap-12.flex:not(.flex-col):not(.flex-wrap) > *:not(:last-child) {
    margin-right: 3rem;
  }

  .gap-3.flex-col > *:not(:last-child),
  .gap-3.flex-wrap > *:not(:last-child),
  .gap-3:not(.flex) > *:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  .gap-4.flex-col > *:not(:last-child),
  .gap-4.flex-wrap > *:not(:last-child),
  .gap-4:not(.flex) > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .gap-5.flex-col > *:not(:last-child),
  .gap-5.flex-wrap > *:not(:last-child),
  .gap-5:not(.flex) > *:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .gap-6.flex-col > *:not(:last-child),
  .gap-6.flex-wrap > *:not(:last-child),
  .gap-6:not(.flex) > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .gap-7.flex-col > *:not(:last-child),
  .gap-7.flex-wrap > *:not(:last-child),
  .gap-7:not(.flex) > *:not(:last-child) {
    margin-bottom: 1.75rem;
  }

  .gap-8.flex-col > *:not(:last-child),
  .gap-8.flex-wrap > *:not(:last-child),
  .gap-8:not(.flex) > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  .gap-9.flex-col > *:not(:last-child),
  .gap-9.flex-wrap > *:not(:last-child),
  .gap-9:not(.flex) > *:not(:last-child) {
    margin-bottom: 2.25rem;
  }

  .gap-10.flex-col > *:not(:last-child),
  .gap-10.flex-wrap > *:not(:last-child),
  .gap-10:not(.flex) > *:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .gap-11.flex-col > *:not(:last-child),
  .gap-11.flex-wrap > *:not(:last-child),
  .gap-11:not(.flex) > *:not(:last-child) {
    margin-bottom: 2.75rem;
  }

  .gap-12.flex-col > *:not(:last-child),
  .gap-12.flex-wrap > *:not(:last-child),
  .gap-12:not(.flex) > *:not(:last-child) {
    margin-bottom: 3rem;
  }
}
